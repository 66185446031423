<template>
  <div class="filter-item-wrapper">
    <div
      class="filter-item"
      :class="{'horizontal-filter-item': horizontal}">
      <div
        v-if="!hideLabel"
        class="flex items-center justify-between">
        <div :class="nestedItem ? 'filter-nested-item-label' : 'filter-item-label'">
          {{ label }}
          <fa-icon
            v-if="tooltip"
            v-tooltip="tooltip"
            class="ml-6"
            icon="circle-info"
            size="sm"
            color="grey-2">
          </fa-icon>
          <i
            v-if="errors?.length > 0"
            class="fa fa-circle-exclamation ml-6 size-16 text-red" />
        </div>
        <slot name="action"></slot>
      </div>
      <div class="filter-item-slot">
        <slot
          v-if="$slots['filter-item']"
          name="filter-item">
        </slot>
        <span
          v-else-if="['text', 'password', 'number'].includes(type)"
          class="p-input-full form-item"
          :class="{
            [iconConfig?.right ? 'p-input-icon-right' : 'p-input-icon-left']: iconConfig
          }">
          <fa-icon
            v-if="iconConfig"
            :icon="iconConfig.icon"
            :color="iconConfig.color"
            :size="iconConfig.size"
            :type="iconConfig.type"
            :class="`${iconConfig.class} ${iconConfig.right ? 'mr-6' : 'ml-6'}`"
            @click="iconConfig.action">
          </fa-icon>
          <!-- eslint-disable vuejs-accessibility/no-autofocus -->
          <component
            :is="type === 'number' ? 'InputNumber' : 'InputText'"
            v-bind="requiredProps"
            :id="fieldId"
            v-model="valueState"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :autofocus="autofocus"
            :readonly="readonly"
            :class="{ 'p-invalid': errors?.length > 0 }" />
        </span>
        <Dropdown
          v-else-if="type === 'select'"
          v-model="valueState"
          v-bind="{...requiredProps, ...defaultDropdownProps, ...$attrs}" />
        <MultiSelect
          v-else-if="type === 'multiselect'"
          v-model="valueState"
          v-bind="{...requiredProps, ...defaultDropdownProps, ...$attrs}"
          display="chip"
          :pt="{
            root: '!border-grey-4',
            labelContainer: 'text-[#D4D4D8]'
          }">
          <template #chip="slotProps">
            <slot
              v-if="$slots['chip-value']"
              name="chip-value"
              :value="slotProps.value">
            </slot>
          </template>
        </MultiSelect>
        <div
          v-else-if="type === 'checkbox'"
          class="form-item-checkbox">
          <Checkbox
            v-model="valueState"
            v-bind="requiredProps"
            :name="fieldId"
            :binary="true"
            :disabled="disabled"
            :aria-label="label"
            :class="{
              'p-invalid': errors?.length > 0,
              'cursor-not-allowed': disabled
            }"
            @keydown.enter="valueState = !valueState" />
          <label
            v-if="$slots['checkbox-label']"
            :for="fieldId"
            class="cursor-pointer text-14"
            :class="{ 'cursor-not-allowed text-grey-3': disabled }"
            @click.enter="!disabled ? valueState = !valueState : null"
            @keydown.enter="!disabled ? valueState = !valueState : null">
            <slot name="checkbox-label" />
          </label>
          <span
            v-else
            class="cursor-pointer select-none"
            :class="{ 'cursor-not-allowed text-grey-3': disabled }"
            @click="!disabled ? valueState = !valueState : null"
            @keydown.enter="!disabled ? valueState = !valueState : null">
            {{ placeholder }}
          </span>
          <fa-icon
            v-if="tooltip && hideLabel"
            v-tooltip="{ value: tooltip, class: 'min-w-[260px]' }"
            icon="circle-info"
            size="sm"
            color="grey-2">
          </fa-icon>
        </div>
        <div
          v-else-if="type === 'switch'"
          class="flex items-center gap-10">
          <InputSwitch
            v-model="valueState"
            v-bind="requiredProps" />
        </div>
        <Calendar
          v-else-if="type === 'datetime'"
          v-model="valueState"
          v-bind="{...requiredProps, ...$attrs}"
          dateFormat="m/d/y"
          :placeholder="placeholder"
          :disabled="disabled"
          showIcon
          iconDisplay="input"
          icon="fa fa-calendar-o text-grey-3"
          :inputClass="{ 'border-red': errors?.length > 0 }"
          :pt="{
            root: 'relative',
            input: 'pl-16',
            dropdownButton: 'absolute right-0',
          }" />
        <Slider
          v-else-if="type === 'slider'"
          v-model="valueState"
          v-bind="{...requiredProps, ...$attrs}">
        </Slider>
        <slot
          v-if="$slots['append']"
          name="append">
        </slot>
      </div>
      <template v-if="showErrorMessages">
        <div
          v-for="(error, index) of errors"
          :key="index"
          class="input-errors">
          <div :class="['error-msg', errorClass]">
            {{ error.$message }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Slider from "primevue/slider";

import FaIcon from "@/components/utils/FaIcon";

import { useVModel } from "@/composables/utils/useVModel";

export default defineComponent({
  name: "FormItem",
  components: {
    Calendar,
    Checkbox,
    Dropdown,
    InputNumber,
    InputSwitch,
    InputText,
    MultiSelect,
    Slider,
    FaIcon
  },
  props: {
    fieldId: {
      type: String,
      required: false
    },
    // text, select, multiselect
    type: {
      type: String,
      required: false
    },
    value: {
      type: [
        String, Array, Boolean, Date, Object, Number
      ]
    },
    errors: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: (props) => props.label
    },
    items: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    autofocus: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    optionLabel: {
      type: [
        String, Function
      ],
      default: "label",
      required: false
    },
    optionValue: {
      type: [
        String, Function
      ],
      default: "value",
      required: false
    },
    iconConfig: {
      type: Object,
      required: false
    },
    nestedItem: {
      type: Boolean,
      required: false
    },
    horizontal: {
      type: Boolean,
      default: false,
      required: false
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false
    },
    errorClass: {
      type: String,
      required: false
    },
    showErrorMessages: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props) {
    return {
      valueState: useVModel(props, "value")
    };
  },
  computed: {
    requiredProps() {
      return {
        id: this.fieldId
      };
    },
    defaultDropdownProps() {
      return {
        options: this.items,
        optionLabel: this.optionLabel,
        optionValue: this.optionValue,
        placeholder: this.placeholder,
        disabled: this.disabled,
        loading: this.loading,
        scrollHeight: "260px",
        class: "{ 'p-invalid': errors?.length > 0 }"
      };
    }
  },
  watch: {
    valueState() {
      this.$emit("value-change");
    }
  },
  methods: {
    onCalendarHide() {
      // need to blur element so user can click again if still focused
      document.activeElement.blur();
    }
  }
});
</script>

<style lang="scss">
.horizontal-filter-item {
  @apply flex flex-col md:flex-row gap-10;

  .filter-item-label,
  .filter-nested-item-label {
    @apply min-w-[180px] max-w-[180px] flex items-center mb-0 font-medium text-grey-1;
  }

  .filter-item-slot {
    @apply w-full flex items-center gap-10;
  }
}
.filter-item-label {
  @apply text-14 font-bold text-black mb-8;
}
.filter-nested-item-label {
  @apply text-12 font-bold text-grey-2 mb-8;
}
.filter-item-slot > * {
  @apply w-full;
}
.error-msg {
  @apply text-red my-[5px];
}
.form-item-checkbox {
  @apply flex items-start gap-[14px] pl-4 text-grey-1;

  .p-checkbox {
    @apply mt-[1px];
  }
}
</style>
